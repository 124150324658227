<template>
<div class="main">
  <yun-header/>
  <div class="body">
<div class="body_box1">
  <div class="title">问诊人群疾病类型分析</div>
  <div class="dateRange">
    <div class="item" :class="{active:DateZt==1}" @click="changeDate(1)">今日</div>
    <div class="item" :class="{active:DateZt==2}" @click="changeDate(2)">近30天</div>
    <div class="item" :class="{active:DateZt==3}" @click="changeDate(3)">近一年</div>
  </div>
  <div id="myChart_rqjb" style="width: 800px;height: 370px;margin: 0 auto;margin-top: 30px" ></div>
</div>
<div class="body_box2">
  <div class="title">处方流转趋势图</div>
  <div id="myChart_cflz" style="width: 800px;height: 400px;margin: 0 auto"></div>
</div>
<div class="body_box3">
  <div class="title">执业药师响应情况</div>
  <div style="height: 30px"></div>
  <div id="myChart_zyysxy" style="width: 860px;height: 327px" ></div>
</div>
<div class="body_box4">
  <div class="title">处方药销售情况</div>
  <div id="myChart_cfyxs" style="width: 860px;height: 327px;margin: 0 auto;margin-top: 30px" ></div>
</div>
  </div>
</div>
</template>

<script>
import * as echarts from "echarts";
import moment from "moment";
import yunHeader from "@/views/yunHeader";

export default {
  name: "yunUse",
  components:{yunHeader},
  data(){
    return {
      riqi:[],
      DateZt:1,
    }
  },
  created() {
    for(let i=0;i<7;i++){
      this.riqi.push(moment().add(-i,'day').format('MM-DD'))
    }
    this.riqi.sort()
    },
  mounted() {
  this.drawChart_rqjb()
  this.chartZyysxy()
  this.drawChart_cflz()
  this.drawChart_cfyxs()
    },
  methods:{
    changeDate(t){
      this.DateZt=t
      this.drawChart_rqjb()
    },
    drawChart_cfyxs(){
    let  data = [{
        name: '消化系统类',
        value: 95,
      },
        {
          name: '呼吸系统类',
          value: 90,
        },
        {
          name: '心血管类',
          value: 84,
        },
        {
          name: '镇痛消炎类',
          value: 74,
        },
        {
          name: '抗感染类',
          value: 64,
        },
      {
        name: '感冒类',
        value: 54,
      }
      ];
    let  getArrByKey = (data, k) => {
        let key = k || "value";
        let res = [];
        if (data) {
          data.forEach(function(t) {
            res.push(t[key]);
          });
        }
        return res;
      };
     let option = {
        backgroundColor: '',
        grid: {
          top: '2%',
          bottom: -15,
          right: 100,
          left: 50,
          containLabel: true
        },
        xAxis: {
          show: false
        },
        yAxis: [{
          // triggerEvent: true,
          show: true,
          inverse: true,
          data: getArrByKey(data, 'name'),
          axisLine: {
            show: false
          },
          splitLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            show: true,
            interval: 0,
            color: '#fff',
            align: 'left',
            margin: 100,
            formatter: function(value, index) {
              return '{title|' + value + '}'
            },
            rich: {
              title: {
                width: 50,
                align:'right',
                fontSize:24
              }
            }
          },
        }, {
          // triggerEvent: true,
          show: true,
          inverse: true,
          data: getArrByKey(data, 'name'),
          axisLine: {
            show: false
          },
          splitLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            interval: 0,
            // shadowOffsetX: '-20px',
            color: ['#fff'],
            align: 'left',
            verticalAlign: 'center',
            // lineHeight: 40,
            fontSize: 14,
            formatter: function(value, index) {
              return data[index].value
            },
          }
        }],
        series: [{
          type: 'bar',
          showBackground: true,
          backgroundStyle: {
            color: "#293F5E"
          },
          yAxisIndex: 0,
          data: data,
          barWidth: 12,
          itemStyle: {
            normal: {
              color: "#0277FA",
              barBorderRadius:[10, 10, 10, 10]
            },
          },
        }]
      };
      let myChart = echarts.init(document.getElementById("myChart_cfyxs"))
      myChart.setOption(option);
    },
    drawChart_cflz(){//处方流转趋势图
      let xLabel = this.riqi
      let data = ["18", "16", "17", "25", "30", "26",'18']
      let data2 = ["1310", "1086", "967", "1207", "1431", "1095",'1375']
      let data3 = ["215", "176", "248", "279", "318", "279",'339']
      let data4 = ["6", "3", "9", "10", "13", "6","7"]
      let color=["#fd5800","#15EE76","#ffb600","#0070d2"]
      let option = {
        backgroundColor: '',
        tooltip: {
          trigger: 'axis',
          backgroundColor:'transparent',
          axisPointer: {
            lineStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [{
                  offset: 0,
                  color: 'rgba(126,199,255,0)' // 0% 处的颜色
                }, {
                  offset: 0.5,
                  color: 'rgba(126,199,255,1)' // 100% 处的颜色
                }, {
                  offset: 1,
                  color: 'rgba(126,199,255,0)' // 100% 处的颜色
                }],
                global: false // 缺省为 false
              }
            },
          },
          formatter(res){
            // console.log(res)
            return  ''
          }
        },
        legend: {
          // data: ['示范', '一级', '二级'],
          orient: 'horizontal',   // 水平
          //type: 'plain',          // 普通图例
          icon: "circle",
          top: 0,
          right: 30,
          // right: '5%',
          // bottom: '15%',
          //width:280,              // 宽度
          itemGap: 2,            // 间隔
          itemWidth: 10,          // 图形宽度。
          itemHeight: 10,         // 图形高度。
          borderWidth: 0,
          textStyle: {
            fontSize: '14px',
            color: '#4ca9ff',
          }
        },
        grid: {
          top: '15%',
          left: '10%',
          right: '10%',
          bottom: '15%',
          // containLabel: true
        },
        xAxis: [{

          type: 'category',
          boundaryGap: false,
          axisLine: { //坐标轴轴线相关设置。数学上的x轴
            show: false,
            lineStyle: {
              color: '#233653'
            },
          },
          axisLabel: { //坐标轴刻度标签的相关设置
            textStyle: {
              color: '#7ec7ff',
              padding: 16,
              fontSize: 14
            },
            formatter: function(data) {
              return data
            }
          },
          splitLine: {
            show: false,
            lineStyle: {
              color: '#192a44'
            },
          },
          axisTick: {
            show: false,
          },
          data: xLabel
        }],
        yAxis: [{
          name: '',
          nameTextStyle: {
            color: "#7ec7ff",
            fontSize: 16,
            padding: 10
          },
          min: 0,
          splitLine: {
            show: false,
            lineStyle: {
              color: '#192a44'
            },
          },
          axisLine: {
            show: false,
            lineStyle: {
              color: "#233653"
            }

          },
          axisLabel: {
            show: true,
            textStyle: {
              color: '#7ec7ff',
              padding: 16
            },
            formatter: function(value) {
              if (value === 0) {
                return value
              }
              return value
            }
          },
          axisTick: {
            show: false,
          },
        }],
        series: [
          {
            name: '院内处方',
            type: 'line',
            symbol: 'circle', // 默认是空心圆（中间是白色的），改成实心圆
            showAllSymbol: true,
            symbolSize: 8,
            smooth: true,
            label: {
              show: true,
              position: 'top',
              textStyle: {
                color: color[0],
              },
            },
            lineStyle: {
              normal: {
                width: 2,
                color: color[0], // 线条颜色
              },
              borderColor: 'rgba(0,0,0,.4)',
            },
            itemStyle: {
              color: color[0],
              borderColor: "#646ace",
              borderWidth: 2

            },

            areaStyle: { //区域填充样式
              normal: {
                //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: "rgba(25,163,223,.3)"
                },
                  {
                    offset: 1,
                    color: "rgba(25,163,223, 0)"
                  }
                ], false),
                shadowColor: 'rgba(25,163,223, 0.5)', //阴影颜色
                shadowBlur: 20 //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
              }
            },
            data: data
          },
          {
            name: '互联网医院',
            type: 'line',
            symbol: 'circle', // 默认是空心圆（中间是白色的），改成实心圆
            showAllSymbol: true,
            symbolSize: 8,
            smooth: true,
            label: {
              show: true,
              position: 'top',
              textStyle: {
                color: color[1],
              },
            },
            lineStyle: {
              normal: {
                width: 2,
                color: color[1], // 线条颜色
              },
              borderColor: 'rgba(0,0,0,.4)',
            },
            itemStyle: {
              color: color[1],
              borderColor: "#646ace",
              borderWidth: 2

            },

            areaStyle: { //区域填充样式
              normal: {
                //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: "rgba(10,219,250,.3)"
                },
                  {
                    offset: 1,
                    color: "rgba(10,219,250, 0)"
                  }
                ], false),
                shadowColor: 'rgba(10,219,250, 0.5)', //阴影颜色
                shadowBlur: 20 //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
              }
            },
            data: data2
          },
          {
            name: '签约医生',
            type: 'line',
            symbol: 'circle', // 默认是空心圆（中间是白色的），改成实心圆
            showAllSymbol: true,
            symbolSize: 8,
            smooth: true,
            label: {
              show: true,
              position: 'top',
              textStyle: {
                color: color[2],
              },
            },
            lineStyle: {
              normal: {
                width: 2,
                color: color[2], // 线条颜色
              },
              borderColor: 'rgba(0,0,0,.4)',
            },
            itemStyle: {
              color: color[2],
              borderColor: "#646ace",
              borderWidth: 2

            },

            areaStyle: { //区域填充样式
              normal: {
                //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: "rgba(10,219,250,.3)"
                },
                  {
                    offset: 1,
                    color: "rgba(10,219,250, 0)"
                  }
                ], false),
                shadowColor: 'rgba(10,219,250, 0.5)', //阴影颜色
                shadowBlur: 20 //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
              }
            },
            data: data3
          },
          {
            name: '其它',
            type: 'line',
            symbol: 'circle', // 默认是空心圆（中间是白色的），改成实心圆
            showAllSymbol: true,
            symbolSize: 8,
            smooth: true,
            label: {
              show: true,
              position: 'top',
              textStyle: {
                color: color[3],
              },
            },
            lineStyle: {
              normal: {
                width: 2,
                color: color[3], // 线条颜色
              },
              borderColor: 'rgba(0,0,0,.4)',
            },
            itemStyle: {
              color: color[3],
              borderColor: "#646ace",
              borderWidth: 2

            },

            areaStyle: { //区域填充样式
              normal: {
                //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: "rgba(10,219,250,.3)"
                },
                  {
                    offset: 1,
                    color: "rgba(10,219,250, 0)"
                  }
                ], false),
                shadowColor: 'rgba(10,219,250, 0.5)', //阴影颜色
                shadowBlur: 20 //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
              }
            },
            data: data4
          },
        ]
      };
      let myChart = echarts.init(document.getElementById('myChart_cflz'))
      myChart.setOption(option);
    },
    chartZyysxy(){//执业药师响应情况
      let data=[
        { value: 81.7, name: '5分钟内'},
        { value: 11.5, name: '5-15分钟' },
        { value: 5.2, name: '15-30分钟' },
        { value: 1.6, name: '30分钟' },
      ]
      let color=['#0277FA', '#FBC902',  '#C41DF3', '#0096FF']
      let option = {
        tooltip: {
          trigger: 'item',
          formatter: '{b0}: {c0}%'
        },
        legend: {
          orient: 'vertical',
          itemHeight: 12, //修改icon图形大小
          itemWidth: 12, //修改icon图形大小
          show: true,
          top: 'center',
          left: '60%',
          textStyle: {
            rich:{
              title:{
                display:"block",
                width:150,
                fontSize:"24px",
                color: '#fff',
              },
              value:{
                fontSize:"24px",
                color: '#fff',
              }
            }
          },
          itemGap: 10,
          formatter: (name) => {
            let arr=data.filter(x=>x.name==name)
            return `{title|${name}}{value|${arr[0].value}%}`;
          },
        },
        series: [
          {
            name: '库存药品占比分析',
            type: 'pie',
            center:['30%', '50%'],
            radius: ['50%', '86%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },
             labelLine: {
              show: false
            },
            data: data,
            color: color

          }
        ]
      };
      let myChart = echarts.init(document.getElementById("myChart_zyysxy"))
      myChart.setOption(option);
    },
    drawChart_rqjb(){//问诊人群疾病占比分析
      let arr=[]
      if(this.DateZt==1){
        arr=[121, 197, 164, 88, 87]
      }
      if(this.DateZt==2){
        arr=[3630, 5910, 4920, 2640, 2610]
      }
      if(this.DateZt==3){
        arr=[44165, 71905, 59860, 32120, 31755]
      }
      let option = {
        color:["#00D7FE"],
        xAxis: {
          axisLabel: {
            show: true,
            color:'#fff',
            fontSize:"24px"
          },
          type: 'category',
          axisLine: {
            show: true,//显示坐标轴线
          },
          data: ['泌尿系统', '呼吸系统', '心血管', '综合', '消化系统']
        },
        tooltip: {},//加了这个空就能显示鼠标移上去提示
        yAxis: {
          splitLine:false,
          type: 'value'
        },
        series: [
          {
            label:{show:true,position: 'top',color:"#fff"},
            data: arr,
            type: 'bar',
            barWidth:20
          }
        ]
      };
      let myChart = echarts.init(document.getElementById("myChart_rqjb"))
      myChart.setOption(option);
      // if(t==1){
      //   option.series[0].data[0]=240
      //   myChart.setOption(option,true);
      // }else{
      //   myChart.setOption(option);
      // }
    },
  }
}
</script>

<style scoped>
/*日期区间切换---------------------------start*/
.dateRange .item{width:51px;
  text-align: center;
  color: #00d7fe;
  border-right: 1px solid #0277FA;
  cursor: pointer;
  line-height: 24px;
  padding: 3px 5px;
}
.dateRange .active{color: #FFFFFF;
  background-color: #0277FA}
.dateRange{
  border-radius: 5px;
  display: flex;
  border: 1px solid #0277FA;
  position: absolute;
  right: 10px;
  top:50px;
}
/*日期区间切换---------------------------end*/
.title{
  font-size: 28px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #00D7FE;
  padding-top: 9px;
  padding-left: 19px;
}
.main{
  width: 1920px;height: 1080px;overflow: hidden;
  background-image: url("../assets/store/bg.jpg");
}
.body{padding: 0px 35px;margin-top: 10px;display: flex;flex-wrap: wrap;justify-content: space-between}
.body_box1{
  background-image: url("../assets/yun/use/left1.png");
  width: 930px;
  height: 484px;
  position: relative;
}
.body_box2{
  background-image: url("../assets/yun/use/right1.png");
  width: 900px;
  height: 484px;
}
.body_box3{
  margin-top: 16px;
  background-image: url("../assets/yun/use/left2.png");
  width: 930px;
  height: 455px;
}
.body_box4{
  margin-top: 16px;
  background-image: url("../assets/yun/use/right2.png");
  width: 900px;
  height: 455px;
}
</style>
